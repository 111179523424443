.disabled {
  background-color: hsl(0, 0%, 95%) !important;
  user-select: none;
}

.CustomInputToOverrideMUIInputBase {
  border: thin solid #C4C4C4 !important;
  font-size: 0.875rem;
  background: #ffffff;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: OpenSans-regular;
  font-weight: 400;
  line-height: unset;
  border-radius: 0.3125rem;
  letter-spacing: 0;
  text-align: right;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 36px !important;
}
.MUIInputBaseCopy:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
  border: thin solid #C4C4C4 !important;
  outline: 0 !important;
}
.MUIInputBaseCopy {
  width: calc(100% - 10px);
  padding-right: 10px;
  color: currentColor;
  height: 1.1876em;
  margin: 0;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}