@import "../../../../styles/colors";
@import "../../../../styles/utils";

.selectedCounter {
  color: $BLACK_MEDIUM;
  font-size: pixelToRem(12);
  float: right;
  margin-top: pixelToRem(15);
}

.selectAllLabel {
  vertical-align: middle;
  font-size: pixelToRem(14);
  color: $PRIMARY_01;
  font-weight: 600;
}
