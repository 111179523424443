@import "../../styles/colors";
@import "../../styles/utils";
@import "../../styles/breakpoints";

.disabled {
  opacity: .5;
}

.searchPO {
  z-index: 3;
  margin: pixelToRem(10) pixelToRem(20);
  margin-top: pixelToRem(128);
  position: absolute;
  background-color: $white;

  label {
    color: $black;
    font-size: pixelToRem(14);
    font-weight: 600;

    display: block;
    margin-bottom: 0;
    line-height: 1.6;

    .requiredIndicator {
      color: $rac-red;
    }
  }

  .searchWrap {
    display: flex;
    width: pixelToRem(680);
    border: pixelToRem(1) solid #C4C4C4;
    height: pixelToRem(400);

    &.collapsed {
      height: pixelToRem(42);
    }
    &.collapsed2 {
      height: pixelToRem(400);
      border: pixelToRem(0) solid #4c4b63;
    }
  }

  @include desktopUp {
    .searchWrap {
      width: pixelToRem(1000);
    }
  }
}

.placeholder {
  min-height: 20px;
  margin-bottom: 1rem;
}