@font-face {
  font-family: 'OpenSans-bold';
  src: local('MyFont'), url(./fonts/OpenSans-bold.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans-semibold';
  src: local('MyFont'), url(./fonts/OpenSans-semibold.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans-light';
  src: local('MyFont'), url(./fonts/OpenSans-light.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans-regular';
  src: local('MyFont'), url(./fonts/OpenSans-regular.woff) format('woff');
}

.MuiInputBase-input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 36px !important;
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
  text-align: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}