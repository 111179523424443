@import "../../../../styles/colors";
@import "../../../../styles/utils";

.searchList {
  padding-left: pixelToRem(4);
  height: pixelToRem(250);
  overflow-y: auto;

  .checkbox {
    padding: pixelToRem(6);
  }

  .info {
    color: #7B7A7A;
    font-size: pixelToRem(14);
    margin-top: pixelToRem(5);

    &.error {
      color: $rac-red;
    }
  }
}
