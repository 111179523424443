@import "../../../styles/colors";
@import "../../../styles/utils";
@import "../../../styles/breakpoints";

.disabled {
pointer-events: none;
}

.selectionBox {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin: pixelToRem(11) pixelToRem(10) 0;

  .entriesWrap {
    display: flex;
    flex-direction: column;
    width: auto;

    label {
      font-size: pixelToRem(12);
      margin-bottom: pixelToRem(10);
    }

    .entries {
      overflow: auto;

      .emptyMessage {
        font-size: pixelToRem(14);
        line-height: pixelToRem(14);
        color: $BLACK_MEDIUM;
        margin-top: pixelToRem(2);
      }
    }
  }

  &.collapsed {
    height: pixelToRem(22);
    line-height: pixelToRem(22);

    .entriesWrap {
      width: pixelToRem(280);

      .entries {
        height: pixelToRem(22);
        // scroll-snap-type: y mandatory;
      }
    }

    @include desktopUp {
      .entriesWrap {
        width: pixelToRem(592);
      }
    }

  }

  .hiddenCount {
    white-space: nowrap;

    .count {
      background-color: $rac-dark-grey;
      color: $white;
    }
  }
}
