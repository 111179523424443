@import "../../../../styles/utils";

.checkboxRoot {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .checkboxLabel {
    vertical-align: middle;
    font-size: pixelToRem(14);
  }
}

