$rac-blue: #0f99d6;
$rac-light-grey: #f0f0f0;
$rac-grey-disabled: #efefef;
$rac-dark-grey: #505050;
$rac-medium-grey: #b0b0b0;
$rac-red: #ff0000;
$rac-green: #8dc63f;
$rac-yellow: #fed405;
$rac-card-border: #f0f0f0;
$white: #fff;
$black: rgba(0, 0, 0, 0.8);
$rac-grey: #cbcbcb;
$rac-dialog-border: #ffcd00;
$rac-action-border: #dbdbdb;
$rac-blue-dark: #034694;
$rac-black-medium: rgba(0, 0, 0, 0.8);

$PRIMARY_02: #095B80;
$PRIMARY_01: #0c7aab;
$PRIMARY_03: #063D55;
$PRIMARY_04: #0F99D6;
$PRIMARY_05: #B1DEF1;
$PRIMARY_06: #F2FAFC;

$SECONDARY_01: #0C8C8C;
$SECONDARY_02: #006767;
$SECONDARY_03: #004D4D;
$SECONDARY_04: #00B7A7;

$NEUTRAL_01: #252A31;
$NEUTRAL_02: #414A57;
$NEUTRAL_03: #5B6C7A;
$NEUTRAL_04: #B3C0CB;
$NEUTRAL_05: #D2DCE4;
$NEUTRAL_06: #E6EBF0;
$NEUTRAL_07: #F6F9FB;

$RED_BUTTON: #C02F48;

$PRIMARY_MAIN_COLOR: $PRIMARY_04;
$PRIMARY_DARK_COLOR: #034694;
$SUCCESS_COLOR: #8DC63F;
$ERROR_MAIN_COLOR: #FF0000;
$SWITCH_LIGHT_BLUE: #CFEBF7;
$LIGHT_GREY: #B0B0B0;
$SWITCH_DISABLED_GREY: #EFEFEF;
$SWITCH_DARK_GREY: #505050;
$BLACK_MEDIUM: rgba(0, 0, 0, 0.8);
